.menuUl {
  margin: 0;
  padding: 0;
  > li {
    min-height: 48px;
    height: auto;
    padding: 0;
    a {
      padding: 12px 16px;
      aside {
        flex: 1;
      }
    }
    > div {
      flex: 1 1 auto;
    }
  }
}

.menu-item-list {
  .menu-item {
    background: #fafafa;
    padding: 0 15px;
    margin: 10px 0;
    .menu-item {
      padding-right: 0;
    }
  }
}

.menu-item-button {
  text-align: right;
}