.table-responsive {
  td, th {
    padding: 0 15px;
  }
  .sort-cell {
    width: 20px;
    padding: 0;
    color: #ccc;
    &:hover {
      cursor: move;
    }
  }
  td:last-child, th:last-child {
    padding-right: 15px;
    white-space: nowrap;
  }
  td:last-child button, th:last-child button {
    width: 30px;
    height: 30px;
    margin: 0 4px;
  }
  td > span, th > span {
    white-space: normal;
  }
}