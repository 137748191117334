* {
  box-sizing: border-box;
}

html,
body,
.merncms {
  height: 100%;
  margin: 0;
  font-family: 'Montserrat',-apple-system, BlinkMacSystemFont, 'Segoe UI', Montserrat, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #03A9F4;
  &[href^='https://www.froala.com/wysiwyg-editor?k=u'] {
    opacity: 0;
    position: absolute;
    z-index: -1;
    pointer-events: none;
  }
}

.fade {
  opacity: 0;
  transition: opacity .3s ease-out;
  &.in {
    opacity: 1;
  }
}

.merncms {
  width: 100%;
  height: 100vh;
  &-main-body {
    background-color: #fafafa;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    .main {
      > div {
        padding: 40px;
        position: relative;
      }
    }
  }
}

.main {
  .form-control-no-bottom {
    margin-bottom: 0;
  }
  .gird-thumb {
    width: 100px;
    vertical-align: middle;
  }
  .flex-right {
    display: flex;
    justify-content: flex-end;
  }
}

.page-detail {
  .item-image-wrapper {
    position: relative;
    padding: 0 15px;
    margin-bottom: 15px;
    &:nth-child(odd) {
      background: #fafafa;
    }
    button {
      position: absolute;
      right: 0;
      top: 15px;
      z-index: 5;
    }
  }
}

.grid-file {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    max-width: 100%;
  }
  span {
    &.fa {
      font-size: 3rem;
    }
    &.fa-file-pdf-o {
      color: red;
    }
    &.fa-file-word-o {
      color: #00a1f1;
    }
    &.fa-file-excel-o {
      color: #7cbb00;
    }
    &.fa-file-powerpoint-o {
      color: #f65314;
    }
  }
}

.main-loading-overlay {
  width: 100%;
  text-align: center;
}

[draggable] {
  user-select: none;
}

.draggable {
  &:hover {
    background: rgba(5,5,5,.1);
    cursor: move;
  }
}

@media screen and (max-width:767px) {
  .merncms-main-body {
    .main {
      > div {
        padding: 15px;
      }
    }
  }
}
